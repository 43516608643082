.addCreditModalContainer {
  max-height: 550px;
  max-width: 450px;
  margin: auto;
}

.addCreditModalText {
  text-align: center;
  color: #7a7a7a;
  font-size: 14px;
}

.addCreditModalTitle {
  margin-top: 25px;
  margin-bottom: -20px;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: #242d3c;
}

.CancelBtn {
  text-align: center;
  background-color: transparent;
  border: none;
  color: #0055ff;
  font-size: 16px;
  font-weight: 500;
  width: 340px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}
