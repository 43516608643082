.addCreditContainer {
  padding: 5px 20px 5px 20px;
  border-radius: 35px;
  border: solid 1px #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.addCreditAlign {
  margin-right: 25px;
  display: inline-flex;
  text-align: center;
}

.addCreditBtn {
  background-color: transparent;
}

.LoggedInUser {
  color: whitesmoke;
}

.SelectLanguage {
  display: flex;
  justify-content: center;
  background-size: 20px;
  width: 20px;
  height: 20px;
  border: 2px solid;
  color: pink;
}

.VerticalDivider {
  border-left: solid 1px #d3d3d3;
  height: 60px;
  margin: 0px 10px;
}

.LanguageCodes {
  text-align: center;
  font-size: 15px;
  margin: 5px;
}

.CurrentLanguageCode {
  color: white;
  text-align: center;
  font-size: 15px;
  margin: 5px;
}

.HeaderUserBox {
  background-color: #ffffff;
  border-radius: 8px;
  border: solid 1px #d3d3d3;
  margin-right: 10px;
}

.MenuItemsText {
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: #242d3c;
}

.DividerPositioning {
  display: flex;
  justify-content: center;
}

.Divider {
  /*margin-top: 15px !important;*/
  border: solid 1px #f9f9f9;
  width: 80%;
}

.ListItemPadding {
  padding: 10px 50px 10px 20px !important;
}
.ListItemPaddingTop {
  padding: 30px 50px 10px 20px !important;
}

.ListItemPaddingBottom {
  padding: 15px 50px 20px 20px !important;
}

.ListItemPaddingInviteFriend {
  padding: 10px 50px 10px 20px !important;
}

.ListItemPadding:hover {
  background-color: #eeeeee;
}

.ListItemPaddingTop:hover {
  background-color: #eeeeee;
}

.ListItemPaddingInviteFriend:hover {
  background-color: #eeeeee;
}

.ListItemPaddingBottom:hover {
  background-color: #eeeeee;
}
