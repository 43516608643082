.CancelBtn {
  cursor: pointer;
  background-color: transparent;
  text-decoration: none;
  border: none;
  margin: 5%;
  text-align: center !important;
  font-size: 18px;
  position: absolute;
  left: 0;
  right: 0;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0055ff !important;
}
