@import './variables';

.LoginHeader {
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  color: #242d3c;
  margin-bottom: 30px;
}

.LoginContainer {
  padding: 58.5px 64px;
  border-radius: 8px;
  box-shadow: $box-shadow-login;
  border: solid 1px #f3f3f3;
  background-color: #ffffff;
}

.RegisterContainer {
  padding: 10px 64px;
  border-radius: 8px;
  box-shadow: $box-shadow-login;
  border: solid 1px #f3f3f3;
  background-color: #ffffff;
}

.CenterLoginContainer {
  display: flex;
  justify-content: center;
}

.OtherOptionText {
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin-top: 35px;
  color: #0055ff !important;
  font-size: 14px;
  font-weight: 500;
}

.OtherOptionTextBottom {
  margin-top: 10px;
}

.Background {
  background-image: url('../../../src/assets/images/ShapesBg.png');
  background-size: cover;
}

.TwoFactorAuthText {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #7a7a7a;
}

.DidntReceiveCode {
  margin-top: 10% !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #242d3c;
}

.SendCodeAgain {
  color: #0055ff !important;
  font-size: 14px;
  font-weight: 500;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 500px #e8f0fe inset;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 500px #e8f0fe inset;
  -webkit-text-fill-color: #242d3c;
}
