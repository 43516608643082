.BigButtons {
  cursor: pointer;
  height: 13rem;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  box-shadow: 0 5px 12px 0 rgba(229, 228, 228, 0.4);
  border: solid 1px #d3d3d3;
  background-color: #ffffff;
}

.BigButtons:hover {
  background-color: #f9f9f9;
}
