.SignInBtn {
  text-align: center;
  background-color: #0055ff;
  color: #ffffff;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  width: 333px;
  margin: 20px 0px;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.SignInBtn:hover {
  background-color: #001753;
  box-shadow: 0px 10px 10px #e0e0e0;
  cursor: pointer;
}

.addCreditBtn {
  text-align: center;
  background-color: #0055ff;
  color: #ffffff;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  margin-bottom: 5%;
  width: 333px;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}
.addCreditBtn:hover {
  background-color: #001753;
  box-shadow: 0px 10px 10px #e0e0e0;
  cursor: pointer;
}

.changePasswordBtn {
  text-align: center;
  background-color: #0055ff;
  color: #ffffff;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  width: 204px;
  margin-top: 5%;
  cursor: pointer;
}

.changePasswordBtn:hover {
  background-color: #001753;
  box-shadow: 0px 10px 10px #e0e0e0;
  cursor: pointer;
}

.deleteAccountBtn {
  margin-top: 5%;
  text-align: center;
  color: #ff4949;
  border: solid 1px #ff4949;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  width: 333px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.deleteAccountBtn:hover {
  color: #ffffff;
  background-color: #ff4949;
  box-shadow: 0px 10px 10px #e0e0e0;
}

.LogoutBtn {
  color: #ff4949;
}

.NewDocumentBtn {
  max-width: 250px;
  text-align: center !important;
  padding: 11px 43px 12px 44px !important;
  border: solid 1px #0055ff !important;
  border-radius: 35px !important;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0055ff;
}

.CopyDiscount {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0055ff;
  padding: 5px 10px 5px 10px !important;
}

.CopyDiscount:hover {
  background-color: #001753;
  color: white;
  border-radius: 35px !important;
}

.InviteFriendBoxes {
  cursor: pointer;
  height: 13rem;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  box-shadow: 0 5px 12px 0 rgba(229, 228, 228, 0.4);
  border: solid 1px #d3d3d3;
  background-color: #ffffff;
}
