@import './variables';

.Title {
  margin-top: -5%;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: #242d3c;
}

.Text {
  text-align: center;
  color: #7a7a7a;
  font-size: 14px;
}

.Dates {
  text-align: center;
  font-size: 14px;
  color: #242d3c;
}

.ChangePwdContainer {
  max-width: 700px;
  padding: 58.5px 75px;
  border-radius: 8px;
  background-color: #ffffff;
}

.ChangePwdTitle {
  margin-top: -6%;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: #242d3c;
}

.WhiteBox {
  border-radius: 8px;
  border: solid 1px #f3f3f3;
  background-color: #ffffff;
}

.WhiteBoxContainer {
  display: flex;
  justify-content: center !important;
  //height: calc(95%) !important;
  margin-top: 6.5% !important;
  overflow: hidden;
}

.CenterContainer {
  display: flex !important;
  justify-content: center !important;
}

.ToDashboard {
  color: #0055ff !important;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.FormBtns {
  display: flex;
  justify-content: center;
}

.CancelSubscriptionGrid {
  align-items: center;
  display: flex;
  justify-content: center;
}

.SubscriptionDatesContainer {
  display: flex;
  justify-content: space-between;
  margin: 7% 0;
}

.ChangeAccountForm {
  margin-top: 5%;
  width: 100%;
  border-radius: 8px;
}

.AccountTitle {
  margin-top: 5%;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: #242d3c;
}

.InviteFriendBtnText {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7a7a7a;
}

.InviteFriendBtnCount {
  font-size: 35px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #242d3c;
}

.CardGreyBackground {
  box-shadow: none !important;
  background-color: #f9f9f9 !important;
  padding: 30px 10px !important;
  margin-bottom: -8%;
}

.SendCodeText {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7a7a7a;
  margin: 10px;
}
