.TitleText {
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  color: #242d3c;
  margin-bottom: 30px;
}

.DownloadsTitleText {
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  color: #242d3c;
}

.SubtitleText {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #7a7a7a;
}

.ImportBtns {
  margin-top: 10px;
}

.SepaComponentBtnsText {
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0055ff;
}

.NewDocumentBtn {
  text-align: center !important;
  padding: 11px 43px 12px 44px !important;
  border: solid 1px #0055ff !important;
  border-radius: 35px !important;
}

.NewDocumentBtn:hover {
  background-color: #f9f9f9;
}

.DownloadsNfText {
  margin-top: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0055ff;
}

.CenterNewDocumentBtn {
  display: flex;
  justify-content: center;
}
.DateDownloadText {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #7a7a7a;
}

.DownloadsRow {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: #242d3c;
}

.CenterNewDocumentBtn {
  display: flex;
  justify-content: center;
}
